import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
import { navigate } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Button = makeShortcode("Button");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "dealing-with-increased-risk-of-family-violence"
    }}>{`Dealing with increased risk of family violence`}</h2>
    <Box display="flex" justifyContent="center" mdxType="Box">
  <Button onClick={() => navigate('/m2-parenting-in-pandemic/29-seek-help')} variant="contained" color="primary" mdxType="Button">
    Skip Topic
  </Button>
    </Box>
    <p>{`Some young people may be at increased risk of experiencing family violence during the pandemic. This section discusses why, and what you can do if you, your teen, or family are at risk.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m8/41-01.svg" alt="Child on Stairs" mdxType="SingleImage" />
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m2/38-sensitive-topic.png" alt="Sensitive Topic Warning" mdxType="SingleImage" />
    <p>{`This section will cover:`}</p>
    <ul>
      <li parentName="ul">{`Why is the risk of violence increased during the pandemic?`}</li>
      <li parentName="ul">{`What to do if you think your teen is at risk`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      